<!-- =========================================================================================
    File Name: MySpacesParent.vue
    Description: Page to show all the placement that belong to a user
========================================================================================== -->

<template>
  <div>
    <!-- {{ dataReady }}
    {{ spaces }}
    {{ anchors }}
    {{ hobjects }}
    {{ nodes }} -->
    <my-spaces-child
      v-if="dataReady && spaces"
      v-bind:spaces="spaces"
      v-bind:anchors="anchors"
      v-bind:hobjects="hobjects"
      v-bind:nodes="nodes"
      v-bind:labels="labels"
      v-bind:spaces_labels="spaces_labels"
    ></my-spaces-child>
  </div>
</template>

<script>
import MySpacesChild from './MySpacesChild.vue'

export default {
  data() {
    return {
      dataReady: false,
      layer_pid: JSON.parse(localStorage.getItem('layerInfo')).pid,
    }
  },
  components: {
    MySpacesChild,
  },
  computed: {
    spaces() {
      if (this.$store.state.hoverlay) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.$store.state.hoverlay.spaces.sort(this.compareDate)
      } else return null
    },
    anchors() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.anchors
      } else return null
    },
    nodes() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.nodes
      } else return null
    },
    hobjects() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.hobjects
      } else return null
    },
    labels() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.labels
      } else return null
    },
    spaces_labels() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.spaces_labels
      } else return null
    },
  },
  mounted() {},
  async created() {
    this.dataReady = false
    await this.getUserLayerSpaces()
    this.dataReady = true
  },
  methods: {
    async getUserLayerSpaces() {
      this.$vs.loading()
      await this.$store.dispatch('hoverlay/getUserLabels')
      await this.$store.dispatch('hoverlay/getUserLayerSpaces', { pid: this.layer_pid })
      this.$vs.loading.close()
    },
    compareDate(a, b) {
      if (a.updated_at_timestamp < b.updated_at_timestamp) {
        return 1
      }
      if (a.updated_at_timestamp > b.updated_at_timestamp) {
        return -1
      }
      return 0
    },
  },
}
</script>

<style lang="scss"></style>
