import * as THREE from 'three'

function destroyLeaflet(params) {
  params.leafletMap.eachLayer(function(layer) {
    params.leafletMap.removeLayer(layer)
  })
  params.layers.forEach(layer => {
    layer = null
  })
  params.leafletMap.off()
  params.leafletMap.remove()
}

function destroyThreejs(params) {
  // pause the animation
  params.renderer.setAnimationLoop(null)
  params.renderer && params.renderer.renderLists.dispose()
  params.renderer.dispose()

  // Remove Orbit
  params.scene.remove(params.orbit)

  while (params.scene.children.length > 0) {
    params.scene.remove(params.scene.children[0])
    disposeHierarchy({ node: params.scene }, disposeNode)
  }
}

function disposeNode(parentObject) {
  parentObject.traverse(function(node) {
    if (node instanceof THREE.Mesh) {
      if (node.geometry) {
        node.geometry.dispose()
      }
      if (node.material instanceof THREE.Material) {
        if (node.material.map) node.material.map.dispose()
        if (node.material.lightMap) node.material.lightMap.dispose()
        if (node.material.bumpMap) node.material.bumpMap.dispose()
        if (node.material.normalMap) node.material.normalMap.dispose()
        if (node.material.specularMap) node.material.specularMap.dispose()
        if (node.material.envMap) node.material.envMap.dispose()
        // disposes any programs associated with the material
        node.material.dispose()
      }
    }
  })
}

function disposeHierarchy(params, callback) {
  for (var i = params.node.children.length - 1; i >= 0; i--) {
    var child = params.node.children[i]
    disposeHierarchy({ node: child }, callback)
    callback(child)
  }
}
export { disposeNode, disposeHierarchy, destroyThreejs, destroyLeaflet }
