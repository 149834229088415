<!-- =========================================================================================
  File Name: PlacementSideBar.vue
  Description: Edit Placement and list Hobjects - Sidebar component
========================================================================================== -->

<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>EDIT PLACEMENTS</h4>
      <!-- <h4>{{ Object.entries(this.selectedPlacements).length === 0 ? "ADD NEW" : "UPDATE" }} ITEM</h4> -->
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <div class="vx-row mb-6 mb-5">
          <div class="vx-col w-1/2">
            <span class="vs-input--label">Expiration Date</span>
            <flat-pickr placeholder="From Date" v-model="present_to" />
          </div>
          <div class="vx-col w-1/2">
            <vs-input
              class="w-full mb-6"
              label="Radius of (meters)"
              v-model="range"
              name="item-name"
              v-validate="'required'"
            />
          </div>
        </div>
        <span class="text-danger text-sm" v-if="errorpresent_to">{{ errorpresent_to }}</span>
        <span class="text-danger text-sm" v-if="errorRange">{{ errorRange }}</span>

        <vs-list>
          <vs-list-header icon-pack="feather" icon="icon-users" title="Objects"></vs-list-header>

          <vs-list-item
            v-for="obj in hobjectPlacementArray"
            :key="obj.hobject.pid"
            icon-pack="feather"
            icon="icon-check"
            :title="obj.hobject.name"
            :subtitle="obj.hobject.model_identifier | truncateIdentifier"
          >
            <vs-button
              color="success"
              type="flat"
              icon-pack="feather"
              @click="editHobject(obj.placement.pid)"
              icon="icon-edit"
            ></vs-button>
            <vs-button
              color="danger"
              type="flat"
              icon-pack="feather"
              @click="removeHobject(obj.hobject.pid, obj.placement.pid)"
              icon="icon-trash-2"
            ></vs-button>
          </vs-list-item>
        </vs-list>

        <div class="vx-row mb-6">
          <div class="vx-col w-1/2">
            <vs-button type="border" class="w-full mb-6" @click="editPosition" :disabled="!validate"
              >Edit object positions</vs-button
            >
          </div>
          <div class="vx-col w-1/2">
            <vs-button type="border" class="w-full mb-6" @click="createAndDrop" :disabled="!validate"
              >Add a new object</vs-button
            >
          </div>
        </div>

        <br />
        <vx-timeline :data="timelineData" />
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!validate">Save</vs-button>
      <!-- <vs-button color="danger" class="mr-6" @click="deletePlacement">Delete</vs-button> -->

      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VxTimeline from '@/components/timeline/VxTimeline'

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    initialSelectedPlacements: {},
  },
  components: {
    VuePerfectScrollbar,
    flatPickr,
    VxTimeline,
  },

  data() {
    return {
      errorpresent_to: '',
      errorRange: '',
      minutes: null,
      present_to: null,
      hobjectPlacementArray: [],
      selectedPlacements: this.initialSelectedPlacements,
      range: null,
      firstPlacement: null,
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      icon: 'UsersIcon',
      statisticTitle: 'Total views of the placement',
      color: 'primary',
      test: 99999,
      timelineData: [
        {
          color: 'success',
          icon: 'EyeIcon',
          title: this.test + ' views',
          desc: 'Candy ice cream cake. Halvah gummi bears Cupcake gummi bears soufflé caramels candy.',
          // time: '25 days ago'
        },
        {
          color: 'primary',
          icon: 'UserCheckIcon',
          title: 'Seen few days ago',
          desc: 'Candy ice cream cake. Halvah gummi bears Cupcake gummi bears.',
          // time: '28 days ago'
        },
      ],
    }
  },
  watch: {
    present_to: {
      handler: function(updatedValue) {
        console.log('present_to changed')
        console.log(updatedValue)

        if (new Date() >= new Date(this.present_to)) {
          this.selectedPlacements.forEach(placement => {
            placement.minutes = 0
          })
        } else {
          console.log('new Date() < this.present_to')

          var diff = Math.abs(new Date(this.present_to) - new Date(this.firstPlacement.present_to))
          this.minutes = Math.floor(diff / 1000 / 60)
          this.selectedPlacements.forEach(placement => {
            placement.minutes = this.minutes
          })
        }
      },
    },
    range: {
      handler: function(updatedValue) {
        if (isNaN(updatedValue) || !updatedValue) return
        console.log('range' + updatedValue)
        console.log(updatedValue)
        this.range = Math.abs(parseFloat(updatedValue))
        this.selectedPlacements.forEach(placement => {
          placement.range = this.range
        })
      },
    },
    isSidebarActive(val) {
      console.log(val)
      this.initValues()
    },
  },
  mounted() {
    // console.log('PlacementSidebar.vue mounted')
  },
  created() {},
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      },
    },
    foundCount() {
      var foundCount = 0
      if (this.selectedPlacements.length > 0)
        this.selectedPlacements.forEach(placement => {
          foundCount += placement.foundCount
        })
      return foundCount
    },
    isFormValid() {
      return !this.errors.any() && this.name && this.pid && this.height > 0
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    },
    hobjects() {
      return this.$store.state.hoverlay.hobjects
    },
  },
  methods: {
    initValues() {
      console.log('PlacementSidebar.vue initValues')
      this.selectedPlacements = this.initialSelectedPlacements
      console.log('this.initialSelectedPlacements len')
      console.log(this.initialSelectedPlacements.length)

      this.firstPlacement = this.selectedPlacements[0]
      console.log(this.firstPlacement)
      this.range = this.firstPlacement.range
      this.present_to = new Date(this.firstPlacement.present_to)

      console.log(this.range)
      console.log(this.present_to)

      this.initHobjects()
    },
    initHobjects() {
      this.hobjectPlacementArray = []
      if (this.selectedPlacements.length > 0)
        this.selectedPlacements.forEach(placement => {
          var hobject = this.hobjects.find(hobject => hobject.pid === placement.hobject_pid)
          console.log('xxxx')
          console.log(hobject)
          if (hobject) {
            this.hobjectPlacementArray.push({
              hobject: hobject,
              placement: placement,
            })
          }
        })
      this.timelineData[0].title = this.foundCount + ' Views'
    },
    createAndDrop() {
      console.log(this.firstPlacement)
      this.isSidebarActiveLocal = false
      console.log('createNewPlacement')
      this.$router.push({
        path: `/create-and-place/${this.firstPlacement.latitude}/${this.firstPlacement.longitude}`,
      })
    },
    editPosition() {
      console.log(this.firstPlacement)
      var placementPids = this.selectedPlacements.map(placement => placement.pid)
      this.isSidebarActiveLocal = false
      console.log('createNewPlacement')
      this.$router.push({
        path: `/position/` + placementPids.join(','),
      })
    },
    submitData() {
      console.log('this.selectedPlacements')
      console.log(this.selectedPlacements)
      this.$store
        .dispatch('hoverlay/updatePlacement', this.selectedPlacements)
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            text: 'Placements updated',
            color: 'success',
            icon: 'check_box',
          })
          console.log('SUCCESS')
          this.$emit('removeMarker')
        })
        .catch(error => {
          console.log(error.message)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    editHobject(placementPid) {
      this.isSidebarActiveLocal = false

      this.$router.push({
        path: `/edit-hobject-placement-anchor/${placementPid}`,
      })
    },
    removeHobject(hobject_pid, placementPid) {
      this.$store
        .dispatch('hoverlay/removeHobject', {
          hobject_pid: hobject_pid,
          placementPid: placementPid,
        })
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            text: 'Hobject removed',
            color: 'success',
            icon: 'check_box',
          })
          this.initHobjects()
          console.log('removeMarker -' + placementPid)
          this.$emit('removeMarker', placementPid)
          if (this.hobjectPlacementArray.length == 0) this.isSidebarActiveLocal = false

          this.selectedPlacements = this.selectedPlacements.filter(function(obj) {
            return obj.pid !== placementPid
          })

          console.log('SUCCESS')
        })
        .catch(error => {
          console.log(error.message)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    validate() {
      this.errorMessageUploads = []
      if (!this.range) {
        this.errorMessageUploads.push('Please enter a range in meters (must be an integer)')
        return false
      }
      if (!this.present_to && !(this.present_to instanceof Date)) {
        this.errorMessageUploads.push('Height must not be empty')
        return false
      }
      if (this.range && this.present_to && !(this.present_to instanceof Date)) {
        return true
      } else return false
    },
  },
  filters: {
    truncateIdentifier: function(value) {
      if (!value) return ''
      value = value.toString()
      return value
        .split('.')
        .slice(-3)
        .join()
    },
  },
}
</script>

<style lang="scss" scoped>
.margin-top {
  margin-top: 25px;
}

.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
